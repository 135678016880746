// ** React Import
import { Suspense, lazy } from 'react';
// ** React Router Dom Import
import { Navigate, useRoutes } from 'react-router-dom';
// ** Guards: Auth and Dashboard
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
// ** Layouts: Auth and Dashboard
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import DashboardLayout from 'src/layouts/dashboard';
// ** Routes path
import ROUTES_PATH from './paths';
// ** Loading screen for lazy loading
import LoadingScreen from 'src/components/LoadingScreen';
import MainLayout from 'src/layouts/main';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { element: <Navigate to={ROUTES_PATH.auth.login} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        { path: 'new-password', element: <NewPasswordPage /> },
        { path: 'verify', element: <VerifyCodePage /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about', element: <AboutPage /> },
        { path: 'contact', element: <ContactPage /> },
        { path: 'start-trial', element: <ContactPage /> },
        { path: 'unsubscribe', element: <UnsubscribeNewsLetterPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'terms-of-service', element: <TermsOfServicePage /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <DashboardHomePage /> },
        { path: 'company/details', element: <CompanyDetailsPage /> },
        { path: 'company/address', element: <CompanyAddressPage /> },
        { path: 'company/settings', element: <CompanySettingsPage /> },
        { path: 'plan/list', element: <PlanListPage /> },
        { path: 'plan/history', element: <PlanListHistoryPage /> },
        { path: 'plan/history/invoice/:id', element: <PlanListHistoryViewPage /> },
        { path: 'quotation/list', element: <QuotationListPage /> },
        { path: 'quotation/create', element: <QuotationCreateEditPage /> },
        { path: 'quotation/edit/:id', element: <QuotationCreateEditPage /> },
        { path: 'quotation/copy/:id', element: <QuotationCreateEditPage /> },
        { path: 'quotation/view/:id', element: <QuotationViewPage /> },
        { path: 'customer/list', element: <CustomerListPage /> },
        { path: 'my-team/list', element: <ManageMemberListPage /> },
        { path: 'my-team/manage-code', element: <ManageTeamCodePage /> },
        {
          path: 'profile',
          children: [
            { element: <Navigate to={ROUTES_PATH.profile.account} replace />, index: true },
            { path: 'account', element: <ProfileAccountPage /> },
          ],
        },
        // These routes are used for website only
        { path: 'manage-website', element: <MyWebsiteHomePage /> },
        { path: 'package/list', element: <PackageListPage /> },
        { path: 'package/create', element: <PackageCreateEditPage /> },
        { path: 'package/edit/:id', element: <PackageCreateEditPage /> },
        { path: 'package/copy/:id', element: <PackageCreateEditPage /> },
        { path: 'package/view/:id', element: <PackageViewPage /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout removeLogo />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Page404 /> },
      ],
    },
  ]);
}

// ** Auth Pages
const LoginPage = Loadable(lazy(() => import('src/pages/auth/Login')));
const RegisterPage = Loadable(lazy(() => import('src/pages/auth/Register')));
const ResetPasswordPage = Loadable(lazy(() => import('src/pages/auth/ResetPassword')));
const NewPasswordPage = Loadable(lazy(() => import('src/pages/auth/NewPassword')));
const VerifyCodePage = Loadable(lazy(() => import('src/pages/auth/VerifyCode')));

// ** Dashboard Page
const HomePage = Loadable(lazy(() => import('src/pages/Home')));
const DashboardHomePage = Loadable(lazy(() => import('src/pages/dashboard-copy/DashboardHome')));

// ** Company: Details and Address page
const CompanyDetailsPage = Loadable(lazy(() => import('src/pages/company/CompanyDetails')));
const CompanyAddressPage = Loadable(lazy(() => import('src/pages/company/CompanyAddress')));
const CompanySettingsPage = Loadable(lazy(() => import('src/pages/company/CompanySettings')));

// ** Quotation list and create or update
const QuotationListPage = Loadable(lazy(() => import('src/pages/quotation/QuotationList')));
const QuotationCreateEditPage = Loadable(lazy(() => import('src/pages/quotation/QuotationCreateViewEdit')));
const QuotationViewPage = Loadable(lazy(() => import('src/pages/quotation/QuotationCreateViewEdit')));

// ** My Team
const ManageTeamCodePage = Loadable(lazy(() => import('src/pages/my-team/ManageTeamCode')));
const ManageMemberListPage = Loadable(lazy(() => import('src/pages/my-team/ManageMemberList')));

// ** Plans Page
const PlanListPage = Loadable(lazy(() => import('src/pages/plan/PlanList')));
const PlanListHistoryPage = Loadable(lazy(() => import('src/pages/plan/PlanListHistory')));
const PlanListHistoryViewPage = Loadable(lazy(() => import('src/pages/plan/PlanListHistoryViewPage')));

// ** Customer pages
const CustomerListPage = Loadable(lazy(() => import('src/pages/customer/CustomerList')));

// ** profile: Account page
const ProfileAccountPage = Loadable(lazy(() => import('src/pages/profile/ProfileAccount')));

// These pages are used for manage my website --------------------

// ** Website
const MyWebsiteHomePage = Loadable(lazy(() => import('src/pages/my-website/MyWebsiteHome')));

// ** Package list and create or update
const PackageListPage = Loadable(lazy(() => import('src/pages/package/PackageList')));
const PackageCreateEditPage = Loadable(lazy(() => import('src/pages/package/PackageCreateViewEdit')));
const PackageViewPage = Loadable(lazy(() => import('src/pages/package/PackageCreateViewEdit')));

// ** Other Pages
const AboutPage = Loadable(lazy(() => import('src/pages/About')));
const ContactPage = Loadable(lazy(() => import('src/pages/Contact')));
const UnsubscribeNewsLetterPage = Loadable(lazy(() => import('src/pages/UnsubscribeNewsLetter')));
const TermsOfServicePage = Loadable(lazy(() => import('src/pages/TermsOfService')));
const PrivacyPolicyPage = Loadable(lazy(() => import('src/pages/PrivacyPolicy')));
const FaqsPage = Loadable(lazy(() => import('src/pages/Faqs')));
const ComingSoonPage = Loadable(lazy(() => import('src/pages/ComingSoon')));
const MaintenancePage = Loadable(lazy(() => import('src/pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
const Page403 = Loadable(lazy(() => import('src/pages/Page403')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));
